import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import BlogCard from "../../Components/BlogCard";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../Action/Action";
import Grid from "@mui/material/Unstable_Grid2";
import AdsBanner from "../../../assets/images/adsBanner.png";

const renderLoader = () => <p></p>;

function Blogs() {
  const [postData, setPostData] = useState([]);
  const [open, setOpen] = useState(false);

  // const Lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    getPostData();
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login.LoginDetails.data.token);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getPostData = async () => {
    setPostData([]);
    setOpen(true);
    let seData = {
      url: `/v1/home/homepage/4`,
      // body: sendData,
      headers: headers,
    };

    let res = await GetCallApi(seData);

    setOpen(false);
    if (res?.status === 201) {
      const getData = res.data.data?.map((p) => {
        return {
          ...p,
        };
      });
      setPostData(getData?.length > 0 ? getData : []);
    } else {
      setPostData([]);
    }
  };

  return (
    <>
      <Backdrop
        open={open}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Suspense fallback={renderLoader()}>
        <div className="blog">
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              xl={8}
              sx={{ pr: { xs: 0, md: 3 } }}
            >
              <div className="exploreBox">
                <div className="postList">
                  <BlogCard data={postData} />
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={0} md={1}>
              <Divider
                orientation="vertical"
                sx={{ borderRight: "1px solid" }}
              />
            </Grid> */}
            <Grid
              item
              xs={0}
              md={4}
              lg={4}
              xl={4}
              sx={{
                pl: { xs: 0, md: "24px" },
                borderLeft: "1px solid #ccc",
              }}
              className="ads-grid"
            >
              <img
                src={AdsBanner}
                alt="ad"
                style={{ position: "sticky", top: "120px" }}
              />
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}

export default Blogs;
