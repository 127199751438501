import React from "react";
import "./App.scss";
import "animate.css";
import AppRoute from "./Routes";

function App() {
  return <AppRoute />;
}

export default App;
