import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { GetCallApi } from "../../../Action/Action";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import HomePostCard from "../../Components/HomePostCard/index";
import AdsBanner from "../../../assets/images/adsBanner.png";
import Grid from "@mui/material/Unstable_Grid2";

const renderLoader = () => <p></p>;

function Dashboard() {
  const darkMode = useSelector((state) => state.headerMenu.darkMode);
  // const [postData, setPostData] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = async () => {
    try {
      let seData = {
        url: `/v1/home/homepage/${page}`,
        // body: sendData,
        headers: headers,
      };

      let { data } = await GetCallApi(seData);
      // const response = await fetch(`your-api-endpoint?page=${page + 1}`);

      if (data.data.length === 0) {
        setHasMore(false);
        return;
      }

      setData((prevData) => [...prevData, ...data.data]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching more data:", error);
    }
  };

  const Lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    getPostData();
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login.LoginDetails.data.token);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getPostData = async () => {
    setData([]);
    setOpen(true);
    let seData = {
      url: `/v1/home/homepage/1`,
      // body: sendData,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    setOpen(false);
    if (res?.status === 201) {
      const getData = res.data.data?.map((p) => {
        return {
          ...p,
        };
      });
      setData(getData?.length > 0 ? getData : []);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Backdrop
        open={open}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="dashboard">
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              lg={8}
              xl={8}
              sx={{ pr: { xs: 0, md: 3 } }}
            >
              <div className="exploreBox">
                {false && (
                  <div className="topBtn">
                    <div
                      className={`selectType  ${
                        Lang === "ar" ? "arabic" : "other"
                      }`}
                      style={{ background: darkMode ? "#1B1B1B" : "#d1d1d1" }}
                    >
                      <Link
                        style={{
                          color: darkMode ? "#FFFFFF" : "black",
                          borderRightColor: darkMode ? "#FFFFFF" : "#040406",
                          borderLeftColor: darkMode ? "#FFFFFF" : "#040406",
                        }}
                      >
                        Following
                      </Link>
                      <Link style={{ color: darkMode ? "#FFFFFF" : "#040406" }}>
                        For You
                      </Link>
                    </div>
                  </div>
                )}
                <div className="postList">
                  <HomePostCard
                    cardshow={"cardshow"}
                    dots={true}
                    data={data}
                    hasMore={hasMore}
                    fetchMoreData={fetchMoreData}
                    setData={setData}
                  />
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={0} md={1}>
              <Divider
                orientation="vertical"
                sx={{ borderRight: "1px solid" }}
              />
            </Grid> */}
            <Grid
              item
              xs={0}
              md={4}
              lg={4}
              xl={4}
              sx={{ pl: { xs: 0, md: "24px" }, borderLeft: "1px solid #ccc" }}
              className="ads-grid"
            >
              <img
                src={AdsBanner}
                alt="ad"
                style={{ position: "sticky", top: "120px" }}
              />
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}

export default Dashboard;
