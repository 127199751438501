import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login";
import ForegetPassword from "../pages/ForegetPassword";
import Congratulation from "../pages/Congratulation";
import NewLoginPassword from "../pages/NewLoginPassword";
import TypeCode from "../pages/TypeCode";
import TermsCondition from "../Modules/Pages/Terms&Condition";
import PrivateRoute, { Auth } from "./PrivateRoute";
import Dashboard from "../Modules/Pages/Dashboard";
import ResponsiveDrawer from "../Modules/Pages/SearchBar";
import Blogs from "../Modules/Pages/Blogs";
import Brief from "../Modules/Pages/Brief";
import Studio from "../Modules/Pages/Studio";
import Settings from "../Modules/Pages/Settings";
import Trending from "../Modules/Pages/Trending";
import InBox from "../Modules/Pages/InBox";
import Profile from "../Modules/Pages/Profile";
import ChangePassword from "../Modules/Pages/ChangePassword";
import SaveItem from "../Modules/Pages/SaveItem";
import BlockedAccounts from "../Modules/Pages/BlockedAccounts";
import HelpCenter from "../Modules/Pages/HelpCenter";
import Register from "../pages/Register";
import PrivacyPolicy from "../Modules/Pages/PrivacyPolicy";
import Chats from "../Modules/Pages/Chats";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route
        path={"/"}
        element={<PublicRoute />}
        //   loader={async () => await isTokenExpired()}
      >
        <Route path={"/"} element={<Login />} />
        <Route path={"/register"} element={<Register />} />
        <Route path={"/forgot-passowrd"} element={<ForegetPassword />} />

        <Route path={"/type-code"} element={<TypeCode />} />

        <Route path={"/new-login-pasword"} element={<NewLoginPassword />} />
        <Route path={"/congratulation"} element={<Congratulation />} />
        <Route path={"/privacypolicy"} element={<PrivacyPolicy />} />
        <Route path={"/terms-condition"} element={<TermsCondition />} />
      </Route>
      <Route path={"/"} element={<Auth />}>
        <Route
          path={"/"}
          element={<PrivateRoute />}

          //   loader={async () => await isTokenExpired()}
        >
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/search"} element={<ResponsiveDrawer />} />
          <Route path={"/blogs"} element={<Blogs />} />
          <Route path={"/brief"} element={<Brief />} />
          <Route path={"/chats"} element={<Chats />} />
          <Route path={"/studio"} element={<Studio />} />
          <Route element={<Settings />} path="/settings" />
          <Route element={<Trending />} path="/trending" />
          <Route element={<InBox />} path="/inbox" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<ChangePassword />} path="/changePassword" />
          <Route element={<SaveItem />} path="/saveItem" />
          <Route element={<BlockedAccounts />} path="/blockedaccounts" />{" "}
          <Route element={<HelpCenter />} path="/helpcenter" />
        </Route>
      </Route>
    </Route>
  )
);

const AppRoute = () => {
  return <RouterProvider router={router} />;
};

export default AppRoute;
